import searchGIF from './Search.gif';
import logo from './ATRA_Logo.png';
import './App.css';
import React from 'react';
import SearchBox from './components/SearchBox';
import OpenAI from "openai";

function App() {
  // const [threadMessages, setThreadMessages] = useState([]);

  // const handleSearch = async (searchTerm) => {
  //   const response = await axios.get('https://w9sy5v02ha.execute-api.ap-south-1.amazonaws.com/default/ToolRecommendationLambda', {
  //     params: { query: searchTerm }
  //   });
  //   setResults(response.data);
  // };

  const handleSearch = async (searchTerm) => {
    const openai = new OpenAI({apiKey: "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", dangerouslyAllowBrowser: true});
    const thread = await openai.beta.threads.create();
    await openai.beta.threads.messages.create(
      thread.id,
      {
        role: "user",
        content: searchTerm
      }
    );
    let run = await openai.beta.threads.runs.createAndPoll(
      thread.id,
      { 
        assistant_id: "asst_qgn6T2TkcwUEViVMnRURKSsa"
      }
    );
    setTimeout(await checkRunStatus(run, openai), 5000);
  }

  async function checkRunStatus(threadRun, openai) {
    if (threadRun.status === 'completed') {
      const messages = await openai.beta.threads.messages.list(
        threadRun.thread_id
      );
      let messagesList = document.getElementById("messages-list");
      for (const message of messages.data.reverse()) {
        let li = document.createElement('li');
        li.classList.add("message-attributes");
        if(message.role == "user") {
          li.classList.add("user-message")
        }
        li.innerText = message.content[0].text.value;
        messagesList.appendChild(li);
      }
      let searchInput = document.getElementsByClassName("search-input")[0];
      searchInput.value = "";
    } else {
      console.log(threadRun.status);
    }
  }
  
  return (
    <div className="App">
      <header className="App-header">
      <img src={logo} className="logo" alt="logo" />
      <ul className="messages-list" id="messages-list"/>
      <SearchBox onSearch={handleSearch}/>
        {/* <a
          className="App-link"
          href="https://seven23ai.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a> */}
      </header>
    </div>
  );
}

export default App;
